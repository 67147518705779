<template>
  <div class="app-wrapper">
    <navbar />
    <el-container class="app-container">
      <sidebar />
      <el-main class="main-container">
        <app-main />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from './Components';

export default {
  name: 'layout',
  components: {
    Navbar,
    Sidebar,
    AppMain,
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-wrapper {
  position: unset;
  width: 100%;
  overflow-y: hidden;
}
.sidebar-container {
  min-height: 95vh !important;
}
.main-container {
  background: rgb(255, 255, 255);
  left: 70px;
  top: 50px;
  width: calc(100% - 80px);
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  min-height: 95vh;
  // height: calc(100% - 50px);
  // overflow: hidden;
  .app-main-container {
    width: auto;
    min-height: 95vh;
  }
}
</style>
