<template>
  <el-menu
    mode="vertical"
    class="el-menu-vertical-demo"
    :show-timeout="200"
    :default-active="$route.path"
    :collapse="isCollapse"
  >
    <sidebar-item
      :routes="routes(permissionRoutes)"
      :isCollapse="isCollapse"
      @chance-colapse="changeCollapse()"
    />
  </el-menu>
</template>

<script>
import { mapState } from 'vuex';
import SidebarItem from './SidebarItem';

export default {
  name: 'Sidebar',
  data() {
    return {
      isCollapse: true,
    };
  },
  components: { SidebarItem },
  computed: {
    ...mapState({
      permissionRoutes: (state) => state.permission.addRoutes,
    }),
  },
  methods: {
    routes(permissionRoutes) {
      return permissionRoutes.filter((item) => !item.hidden && item.children);
    },
    changeCollapse() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style>
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
</style>
