<!-- eslint-disable max-len -->
<template>
  <div class="menu-wrapper">
    <div>
      <div v-for="(item, idx) in routesFilter()" :key="idx">
      <router-link :to="item.path + '/' + item.children[0].path" style="width: 100%;" >
        <el-menu-item
          :index="item.path + '/' + item.children[0].path"
          :class="{'is-active': $route.path === item.path + '/' + item.children[0].path}"
        >
          <i :class="getSrc(item.meta.icon)" style="font-size:22px;" />
          <span slot="title">{{ item.meta.title }}</span>
        </el-menu-item>
      </router-link>
    </div>
    </div>
    <el-menu-item @click="$emit('chance-colapse')">
      <i
        class="el-icon-s-fold"
        style="font-size: 28px;"
      />
      <span slot="title">{{ isCollapse ? 'Expandir' : 'Cerrar' }}</span>
    </el-menu-item>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import icon1 from '../../../assets/icon-12.png';
// import icon2 from '../../../assets/icon-22.png';
// import icon3 from '../../../assets/icon-social-r2.png';
// import icon4 from '../../../assets/icon-user.png';

export default {
  name: 'SidebarItem',
  props: {
    routes: {
      type: Array,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: 'Contraer',
    };
  },
  computed: {
    ...mapState({
      permissionRoutes: (state) => state.permission.addRoutes,
      role: (state) => state.auth.user.role.id,
    }),
  },
  methods: {
    getSrc(path) {
      const iconMap = {
        'icon-1': 'el-icon-folder-opened',
        'icon-2': 'el-icon-date', // Agendas
        'icon-3': 'el-icon-user', // Pacientes
        'icon-4': 'el-icon-user-solid', // Comunidad
      };
      return iconMap[path] || null;
    },
    hasOneShowingChildren(children) {
      const showingChildren = children.filter((item) => !item.hidden);
      if (showingChildren.length === 1) {
        return true;
      }
      return false;
    },
    routesFilter() {
      return this.permissionRoutes.filter((item) => !item.hidden
      && item.children && (item.hiddenOnRole ? !item.hiddenOnRole.includes(this.role) : true));
    },
  },
};
</script>

<style scoped lang="scss">
  .menu-wrapper {
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .is-active {
    background-color: rgba(106, 65, 189, 0.706);
    color: #fff;
  }
</style>
